<template>
    <div class="pageBox">
        <div class="bannerBox">
            {{ currentData.name }}
        </div>
        <div class="operBar" v-if="currentData">
            <div class="operCss">
                <div @click="changeType(item.id)" v-for="(item, index) in currentData.type" :key="index" :class="query.types==item.id?'operItem isActive':'operItem'">
                {{ item.name }}
                </div>
            </div>
        </div>
        <div class="mainPage">
            <div class="BoxItem">
                <div class="item" @click="goEntery(item.id)"  v-for="(item,index) in dataList" :key="index">
                     <div class="imgBox">
                        <img :src="item.cover"/>
                     </div>
                    <div class="itemMsg">
                        <div class="title">{{ item.title }}</div>
                        <div class="itemTips">
                            <div style="margin-right: 50px;">时间：{{ item.createtime }}</div>
                            <div>来源：{{ item.sourcename }}</div>
                        </div>
                        <div class="msg">
                           {{ item.summary }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination"> 
                <el-pagination 
                layout="total, prev, pager, next" 
                :current-page="query.page"
                :page-size="query.size"
                @current-change="pageChange"
                :total="total" />
            </div>
            
        </div>
    </div>
</template>
<script>
import { OH_byType } from '@/api/openHome.js'
export default{
    data(){
        return{
            data:[
                {name:'平台接入', type:[{id:60,name:'接入案例'},{id:61,name:'接入规范'},{id:62,name:'接入流程'}]},
            ],
            query:{
                types:null,
                page:1,
                size:10
            },
            currentData:{},
            dataList:[],
            total:0,
        }
    },
    mounted(){
        this.currentData = this.data[this.$route.query.type]
        this.changeType( this.currentData.type[0].id)
    },
    methods:{
        changeType(type){
            this.query.types = type
            this.CX(0)
        },
        CX(type){
            if(type == 0){
                this.query.page = 1
            }
            OH_byType(this.query).then(r=>{
                this.dataList = r.data.list   
                this.total = r.data.total 
            })
        },
        goEntery(id){
            this.$router.push({
                query:{'id':id},
                path:'/openDetils'
            })
        },
        pageChange(page){
            this.query.page = page
            this.CX(1)
        }
        
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
    background-color:white;
}
.bannerBox{
    width:100%;
    height:280px;
    background-image: url('../../assets/loginout/banner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    line-height: 280px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 10px;
    color: white;
}
.operBar{
    width: 100%;
    
    border-bottom: 1px solid rgb(209, 209, 209);
    box-sizing: border-box;
    .operCss{
        width: 1300px;
        margin:  0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }
    .operItem{
        width: 100px;
        height:50px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
    }
    .isActive{
        border-bottom: 2px solid #399BFD;
        box-sizing: border-box;
        color: #399BFD;
    }
    .operMargin{
        margin-left: 150px;
        margin-right: 150px;
    }
}
.mainPage{
    width: 1400px;
    margin:  0 auto;
    padding: 50px 0px;
    box-sizing: border-box;
}
.BoxItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap ;
    .item{
        margin-top: 20px;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background-color: #f7f7f7;
    }
    .imgBox{
        width: 150px;
        height: 150px;
        background-color: rgb(169, 169, 170);
        img{
            width: 100%;
            height: 145px;
            object-fit: contain;
        }
    }
    .itemMsg{
        padding: 10px;
        box-sizing: border-box;
        width: calc(100% - 170px);
        height: 100%;
        .title{
            font-size: 20px;
        }
        .itemTips{
            width: 100%;
            display:flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            color: #999999;
            line-height: 30px;
            border-bottom: 1px solid #dbdbdb;
            .marginCss{
                margin-right: 60px;
            }
        }
        .msg{
            margin-top: 5px;
            color: #787878;
            font-size: 14px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    
}
.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>